<template>
  <el-main class="centerwrap">
    <div class="centerbox">
      <div class="cardone">
        <div class="leftimg">
          <div class="imgbox">
            <img :src="special.Cover" alt />
          </div>
          <h2 class="tittleh2">{{author.name}}</h2>
        </div>
        <div class="righttext">
          <h2 class="titleh2">
            <span class="text">{{special.Special}}</span>
            <router-link
              :to="special.Tid ? {name:'columnInfo',query:{Id:special.Sid,tid:special.Tid}}: {name:'columnInfo',query:{Id:special.Sid,tid:0}}"
              tag="span"
              class="edit iconfont iconbianji2"
              v-if="owner==1"
            ></router-link>
          </h2>
           <p class="textp" v-html="special.Overview" v-if="isAll"></p>
          <p class="textp" v-html="special.Desc" v-else></p>
          <div class="showall" @click="isAll=!isAll">
            <i class="iconfont iconzhedie" v-if="isAll"></i>
            <i class="iconfont iconzhankai1" v-else></i>
            
          </div>
          <dl class="msg">
            <dt>售价：</dt>
            <dd class="blue">￥{{Discount}}</dd>
            <dd class="dec">￥{{special.Price}}</dd>
          </dl>
          <dl class="msg" v-if="special.Price>0">
            <dt>订阅：</dt>
            <dd v-for="item in buyer.users">
              <img :src="item.avatar" alt />
            </dd>
            <dd>等共{{buyer.count}}人</dd>
          </dl>
          <div class="btnbox" v-if="isbuyer!=1 && owner==2 && special.Price>0">
            <el-button type="primary" size="medium">
            <router-link tag="span" :to="{name:'pay',params:{Id:special.Sid,type:2}}">订 阅</router-link>
            </el-button>
            <el-button size="medium" class="bluebtn">推广奖励</el-button>
          </div>
          <div class="btnbox" v-else>
            <el-button size="medium" class="bluebtn">推广奖励</el-button>
            <router-link :to="{name:'specialColumnEdit',query:{sid:special.Sid}}" tag="span" v-if="owner==1"><el-button size="medium" class="bluebtn">发布专栏文章</el-button></router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="centerbox2">
      <el-menu router :default-active="$route.path" class="el-menu-demo" mode="horizontal">
        <el-menu-item :index='("/s/"+Identify).toString()'>专栏文章</el-menu-item>
        <el-menu-item :index='("/columnMain/specialColumn/specialColumnDrafts/"+Identify).toString()' v-if="owner==1">待发布文章</el-menu-item>
        <el-menu-item :index='("/columnMain/specialColumn/specialColumnAuthor/"+Identify).toString()'>专栏作者</el-menu-item>
      </el-menu>
      <router-view></router-view>
    </div>
  </el-main>
</template>
<script>
import { specialIdentifyRequest } from "@/api/project.js";
export default {
  components: {},
  data() {
    return {
      special: {},
      buyer: {},
      isbuyer:'',
      author: {},
       isAll:false,
       owner:''
    };
  },
  created() {
    this.getSpecialIdentify();
  },
  computed: {
    Identify() {
      return this.$route.params.Identify || ""; //当前项目id
    },
    Discount() {
      if (this.special.Price == 0) {
        return (this.special.Discount = "免费");
      } else {
        return ((this.special.Discount * this.special.Price) / 10).toFixed(2);
      }
    }
  },
  methods: {
    async getSpecialIdentify() {
      try {
        const result = await specialIdentifyRequest({
          Identify: this.Identify
        });
        if (result.status == 200) {
          this.special =result.data.special;
          this.buyer = result.data.buyer;
          this.author = result.data.author;
          this.owner=result.data.owner
          this.isbuyer=result.data.isbuyer
          
          this.$store.commit("setColumnOwner",result.data.owner);
        }
      } catch (err) {}
    }
  }
};
</script>
<style  lang="less" scoped>
.centerwrap {
}
.centerbox {
  background: url("../../../assets/imgs/columndetailbg.png") no-repeat;
  background-size: cover;
  padding: 0 0 30px 0;

  .gobackpage {
    padding: 18px 0 0 15px;
    font-size: 16px;
    color: #000000;
    line-height: 20px;
    cursor: pointer;
    i {
      margin-right: 10px;
    }
  }
  .cardone {
    width: 1200px;
    box-sizing: border-box;
    background: #fff;
    -webkit-box-shadow: 0px 0px 10px #e8e8e8;
    box-shadow: 0px 0px 10px #e8e8e8;
    padding: 34px;
    margin: 30px auto 0;
    display: flex;
    .leftimg {
      width: 205px;
      margin-right: 30px;
      .imgbox {
        width: 205px;
        height: 150px;
        border: 1px solid #c3d7ff;
        border-radius: 4px;
        overflow: hidden;
        padding: 10px;
        margin-bottom: 50px;
        box-sizing: border-box;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .tittleh2 {
        font-size: 14px;
        color: #2f95e3;
        text-decoration: underline;
      }
    }
    .righttext {
      flex: 1;
      .titleh2 {
        font-size: 20px;
        margin-bottom: 10px;
        .text {
          min-width: 80px;
        }
        .iconfont {
          color: #11adcf;
          margin-left: 15px;
        }
      }
      .textp {
        font-size: 13px;
        line-height: 24px;
        overflow: hidden;
        margin-bottom: 15px;
      }
      .msg {
        margin-bottom: 5px;
        dt,
        dd {
          font-size: 14px;
          display: inline-block;
        }
        dd {
          color: #888;
          vertical-align: middle;
          margin-right: 10px;
        }
        .blue {
          color: #3377ff;
        }
        .dec {
          text-decoration: line-through;
        }
        img {
          width: 20px;
          height: 20px;
          border: 1px solid #ccc;
          border-radius: 30px;
        }
      }
      .btnbox {
        margin: 30px 0 0;
        /deep/.el-button {
          margin-right: 10px;
        }
        .bluebtn {
          border: 1px solid #2f95e3;
          color: #2f95e3;
          font-weight: normal;
        }
      }
    }
  }
}
.centerbox2 {
  width: 1200px;
  margin: 0 auto;
}
/deep/.el-menu--horizontal {
  padding: 10px 0 0 0;
  margin: 10px 0 0;
  background: none;
  & > .el-menu-item {
    border-bottom: 2px solid #16aed0;
    padding: 4px 4px;
    height: 40px;
    line-height: 28px;
    font-size: 14px;
    color: #222;
    margin-right: 15px;
    &:focus,
    &:hover {
      background: none;
    }
    &.is-active {
      color: #16aed0;
    }
  }
}
.showall {
  text-align: center;
  i {
    font-size: 20px;
    color: #a0a0a0;
    cursor: pointer;
  }
}
.edit {
  cursor: pointer;
}
</style>